import { NavLink } from "react-router-dom";
import logo from "../../assets/img/topdoglogo.png";
import firebase from "../../helpers/base";

const AdminHeader = (props) => {
  const handleLogout = () => firebase.auth().signOut();
  return (
    <header
      id="header"
      className="fixed-top d-flex align-items-center"
      style={{
        background: "#fff",
        boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div className="container d-flex align-items-center">
        <div className="col-lg-6 logo mr-auto">
          <NavLink to="/">
            <span>
              <img src={logo} className="img-fluid animated" alt="" />
            </span>
          </NavLink>
        </div>

        <nav className="col-lg-6 nav-menu d-none d-lg-block">
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/admin">Dashboard</NavLink>
            </li>
            {/* <li>
              <NavLink to="/registrations">Registrations</NavLink>
            </li>
            <li>
              <NavLink to="/contact-requests">Contact Requests</NavLink>
            </li>

            <li>
              <NavLink to="/generate-token">Generate Token</NavLink>
            </li>
            <li>
              <NavLink to="/team">Team</NavLink>
            </li> */}
            <li className="get-started" onClick={handleLogout}>
              <NavLink>Logout</NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default AdminHeader;
