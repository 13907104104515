import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const FlashyWaitlistItem = () => {
  const [color, setColor] = useState("#ff4a4a"); // Starting color

  // Define a list of colors to cycle through
  const colors = ["#ff4a4a", "#ffd700", "#4aff4a", "#4a8bff"];

  useEffect(() => {
    // Change color every 500 milliseconds
    const intervalId = setInterval(() => {
      setColor((prevColor) => {
        // Get the next color in the array, or restart from the beginning
        const nextIndex = (colors.indexOf(prevColor) + 1) % colors.length;
        return colors[nextIndex];
      });
    }, 500);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <NavLink
      onClick={() =>
        (window.location.href = "https://forms.gle/2MoLv5JdBTxcs88V6")
      }
      className="btn-get-started"
      style={{
        color: color, // This will dynamically change the color
        fontWeight: "bold", // Bold text
        cursor: "pointer", // Pointer cursor on hover
        transition: "transform 0.2s ease", // Smooth scaling transition
      }}
      onMouseEnter={(e) => (e.target.style.transform = "scale(1.1)")}
      onMouseLeave={(e) => (e.target.style.transform = "scale(1)")}
    >
      Join Waitlist
    </NavLink>
  );
};

export default FlashyWaitlistItem;
